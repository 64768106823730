import * as React from 'react'
import { useState } from 'react'

import styled from 'styled-components'

import Layout from 'layouts/main'

// COMPONENTS
import { Button } from 'components/styled/button'
import { Input } from 'components/styled/input'

import { SCREEN } from 'styles/screens'
import axios from 'axios'
import { config } from '../config'
import { cloneDeep } from 'lodash'
import queryString from 'query-string'
import { Textarea } from '../components/styled/textarea'

const Container = styled.div`
  min-height: 100vh;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  max-width: 1520px;

  textarea {
    height: calc(var(--spacing) * 36);
  }

  .mb-40px {
    margin-bottom: 40px;
  }

  a {
    color: #0656F1;
  }

  .mb-3rem {
    margin-bottom: 3rem;
  }

  .d-block {
    display: block;
  }

  button {
    font-size: 20px;
    padding: 15px 40px;
  }
`

const Row = styled.div`
  flex-grow: 1;
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 0;
  display: flex;
  flex-wrap: wrap;
  max-width: 1520px;

  .col-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  &.mb-100px {
    margin-bottom: 100px;
  }

  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
    .row {
      flex-direction: column;

      .col-6 {
        max-width: 100%;
        flex: 0 0 100%;
      }
    }

    margin-bottom: 40px;
    &.mb-100px {
      margin-bottom: 60px;
    }
  }

  .title {
    font-weight: bold;
    font-size: 18px;
    margin-top: 4px;
  }

  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    margin-bottom: 20px;
    flex-direction: column;
    &.mb-100px {
      margin-bottom: 40px;
    }
  }

  input, .row, textarea {
    margin-bottom: 20px;
  }
`
const ColumnFive = styled.div`
  -webkit-box-flex: 0;
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
  padding-right: 15px;
  padding-left: 15px;

  &.ml-auto {
    margin-left: auto;
  }

  &.mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  ul {
    padding-left: 20px;

    li {
      margin-bottom: 14px;
      font-weight: normal;
    }
  }

  div.px-0 {
    padding-left: 15px;
    padding-right: 15px;
  }

  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    flex: 0 0 100%;
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
    &.ml-auto {
      margin-left: 0;
    }

    &.mx-auto {
      margin-left: 0;
      margin-right: 0;
    }
  }
`
const Column = styled.div`
  flex: 0 0 auto;
  width: 91.66666667%;
  margin-left: auto;
  margin-right: auto;
  padding-right: 15px;
  padding-left: 15px;

  h5 {
    margin-bottom: 0;
  }

  h6.title {
    font-weight: 500;
    display: flex;
    align-items: center;
    margin-top: 40px;

    img {
      margin-left: 10px;
      width: 20px;
      height: 20px;
    }
  }

  p {
    margin-bottom: 60px;
    margin-top: 10px;
  }

  button {
    margin-bottom: 50px;
  }

  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    flex: 0 0 100%;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
`

const ColumnOne = styled.div`
  padding-left: 15px;
  padding-right: 15px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
  display: flex;
  align-items: center;

  a {
    margin-top: 20px;
    font-size: 16px;
  }

  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
    a {
      font-size: 13px;
    }
  }

  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 20px;
    justify-content: end;
    margin-bottom: 0;
    a {
      margin-top: 0;
    }
`

const UploadVideo = styled.div`
  border: var(--border-size) solid var(--primary-light);
  border-radius: var(--border-radius);
  padding: calc(var(--spacing) * 12);
  transition: all 300ms;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 20px;

  h6 {
    margin-top: 0;
    margin-bottom: 0;
  }

  p, button, h6 {
    font-size: 18px;
  }

  p {
    margin-bottom: 40px;
  }

  button {
    margin-bottom: 15px;
  }
`

const Hr = styled.div`
  height: 0;
  border-top: 1px solid var(--primary-light);
  margin: calc(var(--spacing) * 9) 0;
`
const DEFAULT_FORM_DATA = {
  name: '',
  email: '',
  suggested_solution:''
}

const FormTemplate = () => {

  const [data, setData] = useState(cloneDeep(DEFAULT_FORM_DATA))
  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(false)
  const [file, setFile] = useState(null)
  const [showEmbedInput, setShowEmbedInput] = useState(false)
  const [showOptionalSteps, setShowOptionalSteps] = useState(false)

  React.useEffect(async () => {
    try {
      const token = window.localStorage.getItem('token')
      await axios.get(`${config.API_BASE_URL}/users/profile?token=${token}`)
    } catch (e) {
      window.localStorage.removeItem('user')
      window.localStorage.removeItem('token')
      window.location.href = '/'
    }
  }, [])

  const onFileChange = (value) => {
    setFile(value)
    setShowEmbedInput(false)
    let d = cloneDeep(data)
    d.video_embed_url = null
    setData(d)
  }

  const onChange = (key, value) => {
    let d = { ...data }
    d[key] = value
    setData(d)
  }

  const onChangeStep = (index, value) => {
    let d = { ...data }
    d.steps[index] = value
    setData(d)
  }

  const validate = () => {
    let _errors = {}
    let required = ['name', 'email','suggested_solution']
    required.forEach(r => {
      if (!data[r]) {
        _errors[r] = 'This field is required'
      }
    })

    if(data['suggested_solution'].length > 500){
      _errors['suggested_solution'] = 'Maximum 500 characters'
    }


    setErrors(_errors)
    return Object.keys(_errors).length === 0
  }
  const reset = () => {
    setData(cloneDeep(DEFAULT_FORM_DATA))
    setShowEmbedInput(false)
    setShowOptionalSteps(false)
  }

  const submit = async () => {
    if (!validate()) {
      return
    }
    setLoading(true)

    await createChallange(data)

  }

  const createChallange = async (_data) => {
    const token = window.localStorage.getItem('token')

    try {
      const { id } = queryString.parse(window.location.search)
      let challenge = (await axios.post(`${config.API_BASE_URL}/challenges/apply`, {
        application: { ..._data, id },
        token,
      })).data
      reset()
      alert('Application submitted')
    } catch (e) {
      alert('An error has occurred')
    }
    setLoading(false)
  }

  const onClickImage = () => {
    document.getElementById('selectImage').click()
  }


  return (
    <Layout title={`BuildUp: Form`} hideTitlePrefix={true}>
      <Container fluid>
        <Row>
          <Column>
            <h5>Challenge application form</h5>
            <p>This application will be considered for a challenge in the BuildUp bootcamp.</p>
          </Column>
          <ColumnFive className='mx-auto'>
            <Input error={errors.name} onChange={e => {
              onChange('name', e.target.value)
            }} value={data.name} label='Team Name' tooltiptext='Name of your team'
                   appendIcon={'https://d34rq7yialevbd.cloudfront.net/uploads/info-icon.svg'}
                   type='text' />
            <Input error={errors.email} onChange={e => {
              onChange('email', e.target.value)
            }} value={data.email} label='Email' tooltiptext='Your email'
                   appendIcon={'https://d34rq7yialevbd.cloudfront.net/uploads/info-icon.svg'}
                   type='text' />
            <Textarea
              error={errors.suggested_solution}
              value={data.suggested_solution}
              onChange={e => onChange('suggested_solution', e.target.value)}
              placeholder='Write your suggested solution in no more than 500 characters'
              label='Suggested Solution'
            />
          </ColumnFive>
          <ColumnFive className='mx-auto'>
            <h6 className='title'>Application Guidelines</h6>
            <ul>
              <li>
                Write an opening sentence that stimulates interest as well as conveys an issue.
              </li>
              <li>
                Formulate the problem solution in up to 5 sentences
              </li>
            </ul>

          </ColumnFive>
        </Row>

        <Row>
          <Column>
            <Button disabled={loading} onClick={submit}>{loading ? 'Please wait..':'Submit'}</Button>
          </Column>
        </Row>

      </Container>
    </Layout>
  )
}

export default FormTemplate
